/**
 * Returns Status Constants
 */

export enum StockStatus {
  PICKED = 2,
  PACKED = 3,
  MISSING_STOCK = 4,
  MISSING_PARTS = 5,
  DAMAGED = 6,
  DAMAGED_MISSING_PARTS = 7,
  WRITE_OFF = 8,
}
