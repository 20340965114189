import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }

import { computed, ref, onBeforeMount, onBeforeUnmount, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

// Components - Import
import { BaseContainer, BaseColumn, BaseGrid, BaseSection } from '@/components/primitives';
import { BaseButton, Notification, LoadingSpinner, BaseSpacer, BaseText } from '@/components/partials';
import { ProductList } from '@/components/features';
import { BaseForm, BaseInput, StandAloneLabel } from '@/components/partials/forms';

// Constants - Import
import { StockStatus } from '@/constants/statuses/orderLineStatuses';

// Types - Import
import type { ComputedRef, Ref } from 'vue';
import type { ProductItem } from '@/types/product';
import type { WopsOrderInterface } from '@/classes/order';
import OrderProcessTypes from '@/constants/orderPriority/orderProcessTypes';

// @use - Import
import useOrder from '@/@use/order';
import useOrderLines from '@/@use/orderLines';
import outgoingOrder from '@/store/modules/outgoingOrder';
import { isGiftResource } from '@/helpers/outgoingOrder';
import { OutgoingItemMode } from '@/store/types/outgoingOrder';

// @use - Enoke

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

/*------------------------------
- Vue
-- Import
- Components
-- Import
- Constants
-- Import
- Types
-- Import
--  Local
- @use
-- Import
-- Envoke
- Product click
- Complete pack
- Abandon pack
- errors
-------------------------------*/

// Vue - Import
const router = useRouter();
const store = useStore();
const { products, productCount, item } = useOrderLines();

onBeforeMount(async () => {
  const itemMode: string | null = localStorage.getItem('packing-item-mode');
  if (!products?.value?.length && (itemMode === OutgoingItemMode.ORDER || !itemMode)) {
    await store.dispatch('outgoingOrder/getAssignedItem', OrderProcessTypes.PACKING);
    state.totalBoxes = store.state.outgoingOrder.parcels;
    state.oversizedBox = store.state.outgoingOrder.oversized;
  }
  if (item.value.highlight && item.value.highlight === 1) {
    store.dispatch('page/addMessageBeCareful', 'Be Careful');
  }
});

onBeforeUnmount(async () => {
  await storePackingState();
});

// reactive
const state = reactive({
  totalBoxes: store.state.outgoingOrder.parcels,
  oversizedBox: store.state.outgoingOrder.oversized,
});

const errors: Ref<Record<string, Array<string> | void>> = ref({});
const loading: ComputedRef<boolean> = computed(() => store.getters['outgoingOrder/getStoreLoading']);

const onProductClicked = (product: ProductItem): void => {
  router.push({ name: 'PackingProduct', params: { product: product.id as string } });
};

const isGift: ComputedRef<boolean> = computed(() => isGiftResource(item.value));
const processedProductsCount: ComputedRef<number> = computed(
  () => products.value?.filter((product: ProductItem) => product.status === StockStatus.PACKED).length
);
const completedPacksDetail: ComputedRef<string> = computed(() => {
  if (isGift.value) return '';
  else return `(${processedProductsCount.value}/${productCount.value} processed)`;
});
const canCompletePack: ComputedRef<boolean> = computed(
  () => !products.value?.find((product: ProductItem) => product.color === 'default')
);
const hasIssue: ComputedRef<boolean> = computed(
  () => !!products.value?.find((product: ProductItem) => !product.status)
);
const successButtonText: ComputedRef<string> = computed(() => {
  if (!canCompletePack.value) {
    return `Finish pack ${completedPacksDetail.value}`;
  } else if (hasIssue.value) {
    return 'Hold pack';
  } else {
    return 'Complete pack & print label(s)';
  }
});

const storePackingState = async (): Promise<void> => {
  await store.dispatch('outgoingOrder/updateOutgoingItemDetails', {
    parcels: state.totalBoxes,
    oversized: state.oversizedBox,
  });
};

const onCompletePack = async (): Promise<void> => {
  await storePackingState();
  router.push({
    name: 'PackingConfirm',
  });
};
const updateOversizedBox: (event: boolean) => void = (event: boolean) => {
  state.oversizedBox = event;
};

const incrementTotalBoxes = (): void => {
  state.totalBoxes = state.totalBoxes + 1;
};
const decrementTotalBoxes = (): void => {
  if (state.totalBoxes === 1) return;
  state.totalBoxes = state.totalBoxes - 1;
};
const toggleOversizedBox = (): void => {
  state.oversizedBox = !state.oversizedBox;
};

// Abandon pack
const onAbandonPack = async (): Promise<void> => {
  state.totalBoxes = 1;
  state.oversizedBox = false;

  await store.dispatch('outgoingOrder/unassignItem', OrderProcessTypes.PACKING).catch(({ message }) => {
    errors.value = {
      message: [message],
    };
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseContainer), { class: "v-packing-products" }, {
    default: _withCtx(() => [
      (_unref(products).length || isGift.value)
        ? (_openBlock(), _createBlock(_unref(BaseGrid), {
            key: 0,
            class: "v-packing-products__grid"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(BaseColumn), {
                span: { tablet: '6' },
                class: "v-packing-products__list-wrapper"
              }, {
                default: _withCtx(() => [
                  (!isGift.value)
                    ? (_openBlock(), _createBlock(_unref(ProductList), {
                        key: 0,
                        products: _unref(products),
                        onListItemClicked: onProductClicked
                      }, null, 8, ["products"]))
                    : (_openBlock(), _createBlock(_unref(BaseText), {
                        key: 1,
                        tag: "h2",
                        size: { tablet: 'md' }
                      }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode("Gift with no toys.")
                        ])),
                        _: 1
                      }))
                ]),
                _: 1
              }),
              _createVNode(_unref(BaseColumn), {
                span: { tablet: '1' },
                class: "mobile-hidden"
              }),
              _createVNode(_unref(BaseColumn), {
                span: { tablet: '5' },
                justify: { tablet: 'between' }
              }, {
                default: _withCtx(() => [
                  (!isGift.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createVNode(_unref(BaseGrid), { class: "v-packing-products__boxes" }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(BaseColumn), { span: { default: '5' } }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(StandAloneLabel), { name: "total-boxes" }, {
                                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                                    _createTextVNode("No. of boxes")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(BaseColumn), { span: { default: '7' } }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(BaseGrid), null, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(BaseColumn), {
                                      class: "v-packing-products__boxes-control",
                                      span: { default: '3' }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(BaseButton), { onClick: decrementTotalBoxes }, {
                                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                                            _createTextVNode("-")
                                          ])),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_unref(BaseColumn), {
                                      class: "v-packing-products__boxes-total",
                                      span: { default: '6' }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(BaseInput), {
                                          type: "number",
                                          label: "",
                                          name: "total-boxes",
                                          disabled: true,
                                          value: state.totalBoxes?.toString(),
                                          modelValue: state.totalBoxes,
                                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((state.totalBoxes) = $event))
                                        }, null, 8, ["value", "modelValue"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_unref(BaseColumn), {
                                      class: "v-packing-products__boxes-control",
                                      span: { default: '3' }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(BaseButton), { onClick: incrementTotalBoxes }, {
                                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                                            _createTextVNode("+")
                                          ])),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_unref(BaseSpacer)),
                        _createVNode(_unref(BaseGrid), null, {
                          default: _withCtx(() => [
                            _createVNode(_unref(BaseColumn), { span: { default: '5' } }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(StandAloneLabel), { name: "oversized" }, {
                                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                                    _createTextVNode("DPD")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(BaseColumn), { span: { default: '7' } }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(BaseInput), {
                                  type: "checkbox",
                                  label: "",
                                  name: "oversized-box",
                                  checked: state.oversizedBox,
                                  "onUpdate:checkbox": _cache[1] || (_cache[1] = ($event: any) => (updateOversizedBox($event)))
                                }, null, 8, ["checked"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", null, [
                    _createVNode(_unref(BaseGrid), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(BaseColumn), null, {
                          default: _withCtx(() => [
                            _createVNode(_unref(BaseForm), { onFormSubmitted: onCompletePack }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(BaseButton), {
                                  type: "submit",
                                  "button-disabled": loading.value || !canCompletePack.value,
                                  color: "success"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" Finish pack " + _toDisplayString(completedPacksDetail.value), 1)
                                  ]),
                                  _: 1
                                }, 8, ["button-disabled"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(BaseSpacer)),
                            _createVNode(_unref(BaseButton), {
                              onClick: onAbandonPack,
                              "is-loading": loading.value
                            }, {
                              default: _withCtx(() => _cache[7] || (_cache[7] = [
                                _createTextVNode("Abandon pack")
                              ])),
                              _: 1
                            }, 8, ["is-loading"]),
                            _createVNode(_unref(BaseSpacer), { size: "4" })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_unref(LoadingSpinner), { key: 1 }))
    ]),
    _: 1
  }))
}
}

})